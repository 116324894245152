import { useQuery, useMutation, useQueryClient } from 'react-query';
import * as Sentry from '@sentry/browser';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import cleanDeep from 'clean-deep';
import moment from 'moment';
import { useFormikContext } from 'formik';
import { getUtmFields } from '../utils/get-utm-fields';
import { useFirebase } from '../components/Firebase';
import {
  OCCUPATION_FAMILY_CODE_MOTOR,
  SCHEME_CODE,
} from '../constants/constants';
import { quoteStore } from '../store/quote-store';
import { stepStore } from '../store/step-store';
import { salesforceStore } from '../store/salesforce-store';

export const cacheKeys = {
  lead: 'lead',
  opportunity: 'opportunity',
};

export const useQuoteInfoFromLeadID = (leadID) => {
  const firebase = useFirebase();
  const navigate = useNavigate();
  return useQuery(
    [cacheKeys.lead],
    () => firebase.doGetQuoteInfoFromLeadID({ leadID, scheme: SCHEME_CODE }),
    {
      staleTime: Infinity,
      onError: (err) => {
        console.error(err);
        Sentry.captureException(err);
        salesforceStore.setIsSalesforceWorking(false);
        navigate(
          { pathname: '/form', state: { message: err.message } },
          { replace: true }
        );
      },
      onSuccess: (values) => {
        salesforceStore.saveSalesforceData(values);
      },
    }
  );
};
export const useQuoteInfoFromOpportunityID = (id) => {
  const firebase = useFirebase();
  const navigate = useNavigate();
  return useQuery(
    [cacheKeys.opportunity],
    () =>
      firebase.doGetQuoteInfoFromOpportunityID({
        opportunityID: id,
        scheme: SCHEME_CODE,
      }),
    {
      staleTime: Infinity,
      onError: (err) => {
        console.error(err);
        Sentry.captureException(err);
        salesforceStore.setIsSalesforceWorking(false);
        navigate(
          { pathname: '/form', state: { message: err.message } },
          { replace: true }
        );
      },
      onSuccess: (values) => {
        salesforceStore.saveSalesforceData(values);
      },
    }
  );
};
export const useCreateLead = () => {
  const firebase = useFirebase();
  const navigate = useNavigate();
  const location = useLocation();
  const { utmValues, otherValues } = getUtmFields(location);
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();

  return useMutation(
    async (values) => {
      navigate(`/form`);
      return firebase.doCreateInitialLead(
        {
          OccupationFamily: OCCUPATION_FAMILY_CODE_MOTOR,
          ...values,
          ...utmValues,
          ...otherValues,
        },
        SCHEME_CODE,
        quoteStore.salesforceLeadId,
        quoteStore.salesforceOpportunityId
      );
    },
    {
      onSuccess: async (values) => {
        quoteStore.setValues(values);
        if (values.salesforceOpportunityId) {
          await queryClient.fetchQuery(cacheKeys.opportunity, async () => {
            const result = await firebase.doGetQuoteInfoFromOpportunityID({
              opportunityID: values.salesforceOpportunityId,
              scheme: SCHEME_CODE,
            });
            salesforceStore.saveSalesforceData(result);
            quoteStore.setValues({
              salesforceLeadId: result.leadId,
              salesforceContactId: result.contactId,
              salesforceOpportunityId: result.opportunityId,
            });
          });
          navigate(
            `/opportunity/${values.salesforceOpportunityId}?${searchParams}`,
            { replace: true }
          );
        } else if (values.salesforceLeadId) {
          await queryClient.fetchQuery(cacheKeys.lead, async () => {
            const result = await firebase.doGetQuoteInfoFromLeadID({
              leadID: values.salesforceLeadId,
              scheme: SCHEME_CODE,
            });
            salesforceStore.saveSalesforceData(result);
            quoteStore.setValues({
              salesforceLeadId: result.leadId,
              salesforceContactId: result.contactId,
              salesforceOpportunityId: result.opportunityId,
            });
          });
          navigate(`/lead/${values.salesforceLeadId}?${searchParams}`, {
            replace: true,
          });
        }
      },

      onError: (err) => {
        console.error(err);
        Sentry.captureException(err);
        salesforceStore.setIsSalesforceWorking(false);
      },
    }
  );
};

export const useSavePolicy = () => {
  const firebase = useFirebase();
  const navigate = useNavigate();
  return useMutation(
    (values) =>
      firebase.doSendQuoteForReview({
        values: {
          ...cleanDeep(values, { cleanValues: ['0', 0] }),
          EffectivePeriodStartDate: moment(
            values.EffectivePeriodStartDate
          ).format('DD/MM/YYYY'),
          salesforceOpportunityId: quoteStore.salesforceOpportunityId,
          salesforceLeadId: quoteStore.salesforceLeadId,
          salesforceContactId: quoteStore.salesforceContactId,
        },
        product: SCHEME_CODE,
      }),
    {
      onSuccess: async () => {
        salesforceStore.rateLoader = false;
        localStorage.setItem('previousQuote', '');
        navigate(`/success`, { replace: true });
      },
      onError: (err) => {
        console.error(err);
        Sentry.captureException(err);
        navigate({ pathname: '/error', state: { message: err.message } });
      },
    }
  );
};

export const useVehicleInfoFromRegistrationNumber = () => {
  const firebase = useFirebase();
  const { values } = useFormikContext();
  return useMutation(
    (registrationNumber) =>
      firebase.doGetVehicleInfo({
        registrationNumber,
      }),
    {
      onSuccess: (vehicleInfo) => {
        values.VehicleMake = vehicleInfo.make || '';
        values.YearOfRegistration = vehicleInfo.monthOfFirstRegistration
          ? moment(vehicleInfo.monthOfFirstRegistration, 'YYYY-MM').format(
              'YYYY'
            )
          : undefined;
        values.YearOfManufactured = vehicleInfo.yearOfManufacture || undefined;
        values.EngineType = `${vehicleInfo.engineCapacity || ''} ${
          vehicleInfo.fuelType || ''
        }`;
        stepStore.nextStep();
        window.scrollTo(0, 0);
      },
      onError: () => {
        stepStore.nextStep();
        window.scrollTo(0, 0);
      },
    }
  );
};
