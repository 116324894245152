import React from 'react';
import { css, cx } from '@emotion/css';

const rightSidebarWidthStyle = css`
  grid-area: info;
  padding: 3.5rem 1.5rem 2.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 2;
  @media (max-width: 768px) {
    background-color: unset;
    padding: 0;
  }
`;
const hasChildContainerStyle = css`
  background-color: var(--silver);
`;
const noChildContainerStyle = css`
  @media (max-width: 768px) {
    padding: 0;
  }
`;

export const RightSidebar = (props) => {
  const { children } = props;

  return (
    <aside
      className={cx(
        rightSidebarWidthStyle,
        children ? hasChildContainerStyle : noChildContainerStyle
      )}
    >
      {children}
    </aside>
  );
};
