import { css, cx } from '@emotion/css';
import React from 'react';

const buttonContainerStyle = css`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 1rem;
  margin-top: auto;
  margin-bottom: 1rem;
  z-index: 5;
  border-top: 1px solid var(--primaryBrand);

  > div:first-child {
    width: 100%;
    margin-top: -0.75rem;
  }
`;
const stickyBottomStyle = css`
  position: sticky;
  padding: 1rem 0;
  margin-bottom: 0;
  background-color: var(--white);
  bottom: 0;
  @media (max-width: 768px) {
    left: 0;
    right: 0;
  }
  border-top: 2px solid var(--primaryBrand);
  &:before {
    content: '';
    height: 2px;
    position: absolute;
    top: -2px;
    left: -1rem;
    right: -1rem;
    background-color: var(--primaryBrand);
  }
`;

export const ButtonContainer = (props) => {
  const { children, customAlignment, stickyBottom } = props;
  return (
    <div
      className={cx(
        buttonContainerStyle,
        customAlignment,
        stickyBottom && stickyBottomStyle
      )}
    >
      {children}
    </div>
  );
};
