/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import { css } from '@emotion/css';
import { stepStore } from '../../store/step-store';
import { stateStore } from '../../store/state-store';

const progressContainer = css`
  height: 1rem;
  grid-area: progress;
  margin: 1rem 2rem 0;
  position: relative;

  @media (max-width: 768px) {
    margin: 1rem 1rem 0;
  }
`;

const progressBar = css`
  height: 16px;
  position: absolute;
  width: 100%;
  border-radius: 8px;
  background-color: var(--gray);

  &::-webkit-progress-bar {
    height: 16px;
    border-radius: 8px;
    background-color: var(--gray);
  }
  &::-webkit-progress-value {
    height: 16px;
    border-radius: 8px;
    background-color: var(--primaryBrand);
  }
  &::-moz-progress-bar {
    height: 16px;
    border-radius: 8px;
    background-color: var(--gray);
  }
`;

export const ProgressBar = () => {
  const { step } = stepStore;

  const maxStep = stateStore.stepper.length;

  const progressPoint = css`
    position: absolute;
    height: 16px;
    width: 16px;
    background-color: var(--white);
    border: 4px solid var(--primaryBrand);
    border-radius: 50%;
    left: calc(${(step * 100) / (maxStep - 1)}% - 16px);

    @media (max-width: 768px) {
      top: 0;
    }
  `;

  return (
    <div className={progressContainer}>
      <progress
        id="step-progress"
        className={progressBar}
        value={step}
        max={maxStep - 1}
      >
        Step {step} out of {maxStep}
      </progress>
      <div className={progressPoint} />
    </div>
  );
};
