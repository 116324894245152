import React, { useState } from 'react';
import { css } from '@emotion/css';
import { ReactComponent as MenuIcon } from '../../assets/hamburger.svg';
import { ReactComponent as CloseIcon } from '../../assets/closeIcon.svg';
import headerBB from '../../assets/BBLogoBlue.png';
import headerBBSmall from '../../assets/BBLogoSmall.png';
import insyncLogo from '../../assets/Insync-Logo-New.png';

const headerWrapper = css`
  grid-area: header;
  display: flex;
  flex-direction: column;
  z-index: 6;
`;

const colorHeader = css`
  background: var(--dark);
  padding: 0.25rem 0;
  line-height: 1.625em;
  color: var(--white);
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  letter-spacing: 2px;
  @media (min-width: 320px) and (max-width: 1024px) {
    display: none;
  }
`;

const phoneStyle = css`
  font-size: 0.75rem;
  letter-spacing: 0;
  text-decoration: none;
  color: var(--white);
  > a {
    color: var(--white);
  }
`;

const linkStyle = css`
  color: var(--white);
  text-decoration: none;
  padding-right: 1.25rem;
  font-size: 0.625rem;
  letter-spacing: 2px;
`;

const colorHeaderTabContainerStyle = css`
  display: flex;
  justify-content: space-between;
  min-width: 60%;
  align-items: center;
  height: 1.875rem;
`;

const mainHeaderWrapper = css`
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid var(--primaryBrand);
  @media (max-width: 1024px) {
    border-bottom: none;
  }
`;

const mainHeaderStyle = css`
  display: flex;
  position: relative;
  align-items: center;
  height: 5rem;
  width: 100%;
  justify-content: space-between;
  padding: 1rem 2rem;
  @media (min-width: 320px) and (max-width: 1024px) {
    width: 100%;
    padding: 1rem 1rem;
    justify-content: space-between;
  }
`;

const mainHeaderTabContainerStyle = css`
  display: flex;
  align-items: center;
  @media (min-width: 320px) and (max-width: 1024px) {
    display: none;
  }
`;

const tabStyle = css`
  color: var(--primaryBrand);
  cursor: pointer;
  font-size: 0.75rem;
  line-height: 1.5em;
  text-transform: uppercase;
  text-decoration: none;
  min-width: fit-content;
  letter-spacing: 2px;
  padding: 0 1rem;
  &:hover {
    color: var(--primaryBrand);
  }
`;

const logoStyle = css`
  width: 9.56rem;
  height: 3.206rem;
  padding: 0 1rem;
`;
const imagePadding = css`
  padding-left: 1rem;
  vertical-align: bottom;
  height: 1.9rem;
  @media (max-width: 1200px) {
    display: none;
  }
`;

const imagePaddingSmall = css`
  padding-left: 1rem;
  vertical-align: bottom;
  height: 2.4rem;
  @media (min-width: 1201px) {
    display: none;
  }
`;

const sideBarIcon = css`
  display: none;
  background: unset;
  border: none;
  @media (min-width: 320px) and (max-width: 1024px) {
    display: block;
    padding: 0 1rem;
  }
`;

const sideBarStyle = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1.25rem;
`;

const showSideBarOverlay = css`
  z-index: 9;
  height: 100%;
  width: 100%;
  background: #cac9c9;
  opacity: 0.6;
  position: fixed;
  top: 0;
  left: 0;
`;

const closeIconContainer = css`
  padding: 1.5rem;
  background: unset;
  border: none;
  &:hover {
    stroke: var(--primaryBrand);
  }
`;

const sideBarWrapper = css`
  height: 100%;
  display: flex;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  padding-top: 1rem;
  align-items: flex-start;
  transform: translateX(-101%);
  transition: transform 250ms;
`;

const hideSideBarOverlay = css`
  display: none;
`;

const tabsName = [
  { name: 'Beauty', url: 'https://insyncinsurance.co.uk/beauty-insurance' },
  {
    name: 'Aesthetics',
    url: 'https://insyncinsurance.co.uk/aesthetic-insurance',
  },
  { name: 'Medical', url: 'https://insyncinsurance.co.uk/medical-insurance' },
  { name: 'Business', url: 'https://insyncinsurance.co.uk' },
  {
    name: 'Commercial',
    url: 'https://insyncinsurance.co.uk/commercial-property',
  },
  {
    name: 'Healthcare',
    url: 'https://insyncinsurance.co.uk/business-health-insurance',
  },
];

const upHeaderTabNames = [
  { name: 'Quote', url: 'https://insyncinsurance.co.uk/get-a-quote' },
  { name: 'Benefits', url: 'https://insyncinsurance.co.uk/insync-benefits' },
  {
    name: 'Partner',
    url: 'https://www.partner.insyncinsurance.co.uk/',
  },
  { name: 'About', url: 'https://insyncinsurance.co.uk/about-us' },
  {
    name: 'Reviews',
    url: 'https://www.feefo.com/en-GB/reviews/insync-insurance-solutions-ltd?displayFeedbackType=BOTH&timeFrame=YEAR',
  },
  { name: 'Insurers', url: 'https://insyncinsurance.co.uk/insurance-partners' },
  {
    name: 'Careers',
    url: 'https://insyncinsurance.co.uk/careers',
  },
  { name: 'Support', url: 'https://insyncinsurance.co.uk/customer-support' },
];

export const Header = () => {
  const [showSideBar, setShowSideBar] = useState(false);
  const showSideBarStyle = showSideBar ? { transform: 'translateX(0%)' } : {};

  return (
    <header className={headerWrapper}>
      <div>
        <button
          className={showSideBar ? showSideBarOverlay : hideSideBarOverlay}
          onClick={() => setShowSideBar(false)}
          type="button"
        />
        <div className={sideBarWrapper} style={showSideBarStyle}>
          <div className={sideBarStyle}>
            {tabsName.map((tab) => (
              <a
                href={tab.url}
                className={tabStyle}
                target="_blank"
                rel="noopener noreferrer"
                key={tab.name}
              >
                {tab.name}
              </a>
            ))}
          </div>
          <button
            className={closeIconContainer}
            type="button"
            onClick={() => setShowSideBar(false)}
          >
            <CloseIcon />
          </button>
        </div>
      </div>
      <div className={colorHeader}>
        <div className={colorHeaderTabContainerStyle}>
          {upHeaderTabNames.map((tab) => (
            <a
              className={linkStyle}
              href={tab.url}
              target="_blank"
              rel="noopener noreferrer"
              key={tab.name}
            >
              {tab.name}
            </a>
          ))}
          <p className={phoneStyle}>
            CALL US: <a href="tel:01200 309 516">01200 309 516</a>
          </p>
        </div>
      </div>
      <div className={mainHeaderWrapper}>
        <div className={mainHeaderStyle}>
          <a
            href="https://insyncinsurance.co.uk/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={insyncLogo} alt="logo" className={logoStyle} />
          </a>
          <div className={mainHeaderTabContainerStyle}>
            {tabsName.map((tab) => (
              <a
                href={tab.url}
                className={tabStyle}
                target="_blank"
                rel="noopener noreferrer"
                key={tab.name}
              >
                {tab.name}
              </a>
            ))}
            <a
              href="https://www.bbinsurance.com/about-us/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={headerBB} alt="feefo" className={imagePadding} />
              <img
                src={headerBBSmall}
                alt="feefo"
                className={imagePaddingSmall}
              />
            </a>
          </div>
          <button
            type="button"
            className={sideBarIcon}
            onClick={() => setShowSideBar(true)}
          >
            <MenuIcon />
          </button>
        </div>
      </div>
    </header>
  );
};
