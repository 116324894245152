import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Formik } from 'formik';
import { css } from '@emotion/css';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { get } from 'lodash';
import { InputField } from '../components/InputField';
import { ButtonContainer } from '../components/ButtonContainer';
import { Loader } from '../components/Loader';
import { PageTitle } from '../components/PageTitle';
import { Button } from '../components/Button';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { useCreateLead } from '../../api/api';
// import Maintenance from '../Maintenance/Maintenance';

const bodyStyle = css`
  min-height: calc(100vh - 10px);
  display: grid;
  grid-template-columns: Max(25vw, 14.5rem) 1fr Max(25vw, 14.5rem);
  grid-template-areas:
    'header header header'
    '. main info'
    'footer footer footer';
  background-color: var(--white);
  @media (max-width: 768px) {
    grid-template-areas:
      'header'
      'main'
      'info'
      'footer';
    grid-template-columns: 100%;
    grid-template-rows: auto;
  }
`;

const contentContainer = css`
  grid-area: main;
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 1rem;
  height: calc(100vh - 7.125rem);
  @media (max-width: 1024px) {
    height: calc(100vh - 5rem);
  }
`;

const bottomContainerStyle = css`
  flex-direction: column;
  @media only screen and (max-width: 879px) {
    display: flex;
    gap: 0;
    width: 100%;
  }
  @media only screen and (min-width: 880px) {
    flex-direction: row;
    gap: 2.5rem;
  }
  @media only screen and (min-width: 768px) {
    justify-content: flex-end;
  }
`;

const salesforceValidationScheme = yup.object().shape({
  firstName: yup.string().required('First name is required field.'),
  lastName: yup.string().required('Last name is required field.'),
  phone: yup.string().required('Phone is required field.'),
  email: yup
    .string()
    .email('Email must be a valid email.')
    .required('Email is required field.'),
});

const salesforceInitialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
};

const formContainerStyle = css`
  background-color: var(--primaryBrand);
  border-radius: 15px;
  padding: 1.5rem;
  margin: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto 0;

  @media (max-width: 768px) {
    margin: auto 0.5rem;
  }
`;

const titleStyle = css`
  color: var(--white);
  font-size: 2.25rem;
  margin-bottom: 1.15rem;
  text-align: center;
`;

const infoStyle = css`
  color: var(--white);
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.8;
  text-align: center;
  margin: 0 1.5rem 2rem;
`;

const nameContainerStyle = css`
  display: flex;
  width: 100%;
`;

const spaceBetweenStyle = css`
  width: 10%;
`;

const SalesforceDetails = observer(() => {
  const { mutate: createLead, isLoading: createLeadLoading } = useCreateLead();
  const previousQuote = localStorage.getItem('previousQuote');
  const navigate = useNavigate();
  const [newQuote, setnewQuote] = useState(!localStorage.previousQuote);
  const handleContinue = () => {
    if (previousQuote) {
      const parsedPreviousQuote = JSON.parse(previousQuote);
      if (get(parsedPreviousQuote, 'leadId')) {
        navigate(`/lead/${get(parsedPreviousQuote, 'leadId')}`, {
          replace: true,
        });
      }
      if (get(parsedPreviousQuote, 'opportunityId')) {
        navigate(`/opportunity/${get(parsedPreviousQuote, 'opportunityId')}`, {
          replace: true,
        });
      }
    }
  };
  if (createLeadLoading) {
    return <Loader message="Loading your quote..." />;
  }

  return (
    <div className={bodyStyle}>
      <Header />
      <main className={contentContainer}>
        <Formik
          initialValues={salesforceInitialValues}
          validationSchema={salesforceValidationScheme}
          onSubmit={(values) => {
            createLead(values);
          }}
        >
          {(props) => (
            <div className={formContainerStyle}>
              <PageTitle title="Start Your Quote" customStyle={titleStyle} />
              {newQuote && (
                <p className={infoStyle}>
                  Let us have a few details so we can get started with your
                  quote…
                </p>
              )}
              {newQuote && (
                <>
                  <div className={nameContainerStyle}>
                    <InputField
                      name="firstName"
                      id="firstName"
                      placeholder="First Name"
                      required
                      salesForce
                      fullwidth
                    />
                    <div className={spaceBetweenStyle} />
                    <InputField
                      name="lastName"
                      id="lastName"
                      placeholder="Last Name"
                      required
                      salesForce
                      fullwidth
                    />
                  </div>
                  <InputField
                    name="email"
                    id="email"
                    placeholder="Email"
                    type="email"
                    required
                    salesForce
                    fullwidth
                  />
                  <InputField
                    name="phone"
                    id="phone"
                    placeholder="Phone"
                    type="number"
                    required
                    salesForce
                    fullwidth
                  />
                </>
              )}
              {!newQuote && (
                <>
                  <p className={infoStyle}>
                    Would you like to continue with the previous quote or start
                    a new one?
                  </p>
                </>
              )}
              <ButtonContainer customAlignment={bottomContainerStyle}>
                <Button
                  type="submit"
                  label="Start Quote"
                  handleClick={() => {
                    if (newQuote) {
                      props.handleSubmit();
                    } else {
                      setnewQuote(true);
                    }
                  }}
                  salesForce
                />
                {previousQuote && !newQuote && (
                  <Button
                    handleClick={handleContinue}
                    label="Continue Quote"
                    salesForce
                  />
                )}
                {previousQuote && newQuote && (
                  <Button
                    handleClick={() => setnewQuote(false)}
                    label="Back"
                    salesForce
                  />
                )}
              </ButtonContainer>
            </div>
          )}
        </Formik>
      </main>
      {/* <Maintenance /> */}
      <Footer />
    </div>
  );
});

export default SalesforceDetails;
