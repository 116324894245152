import React from 'react';
import { css } from '@emotion/css';
import { observer } from 'mobx-react';
import { ReactComponent as CircleChecked } from '../../assets/circleChecked.svg';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { stepStore } from '../../store/step-store';
import { Button } from '../components/Button';
import { ButtonContainer } from '../components/ButtonContainer';

const bodyStyle = css`
  min-height: calc(100vh - 10px);
  display: grid;
  grid-template-columns: clamp(17.875rem, 36vw, 24rem) 1fr;
  grid-template-areas:
    'header header'
    'main main'
    'footer footer';
  background-color: var(--white);

  @media (max-width: 768px) {
    grid-template-areas:
      'header'
      'main'
      'footer';
    grid-template-columns: 100%;
    grid-template-rows: auto;
  }
`;

const pageContainerStyle = css`
  grid-area: main;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  color: var(--dark);
  max-width: 60%;
  align-items: center;
  text-align: center;
  min-height: calc(100vh - 7.5rem);

  @media (max-width: 768px) {
    min-height: calc(100vh - 5rem);
    padding: 2rem;
    max-width: 100%;
  }
`;

const oneColumnStyle = css`
  width: 100%;
  justify-content: center;
  margin-top: unset;
  border-top: none;

  @media (max-width: 768px) {
    display: flex;
  }
`;

const contentContainerStyle = css`
  align-items: center;
  text-align: center;
  margin-top: auto;
  > svg {
    height: 8rem;
    width: 8rem;
    > path {
      stroke: var(--primaryBrand);
    }
  }
  > h1 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 3.625rem;
    margin-bottom: 1.5rem;
    line-height: 1.25em;
  }
  > p {
    font-size: 1rem;
    line-height: 1.6em;
  }
`;

const phoneStyle = css`
  color: var(--primaryBrand);
  text-decoration: underline;
`;
const footnoteStyle = css`
  margin-top: auto;
  margin-bottom: 1rem;
  color: var(--darkGray);
`;

const SuccessScreen = observer(() => {
  const handleGoBack = () => {
    stepStore.step = window.innerWidth < 768 ? 0 : 1;
    window.location.href = '/';
  };

  return (
    <div className={bodyStyle}>
      <Header />
      <main className={pageContainerStyle}>
        <div className={contentContainerStyle}>
          <CircleChecked />
          <h1>
            To get you the best possible price your risk is currently being
            assessed by our underwriters.
          </h1>
          <p>
            Typically we will come back to you with your quotation within 24
            hours*, if you need cover sooner, you can contact one of the team on{' '}
            <a className={phoneStyle} href="tel:01200 309516">
              01200 309516
            </a>{' '}
            & we&apos;ll do our best to expediate for you.
          </p>
        </div>
        <p className={footnoteStyle}>* refers to working days</p>
        <ButtonContainer customAlignment={oneColumnStyle}>
          <Button handleClick={handleGoBack} label="Back to Home" />
        </ButtonContainer>
      </main>
      <Footer />
    </div>
  );
});
export default SuccessScreen;
